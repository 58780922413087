'use strict';// main.js or any other file

var initImageCheck = require('../components/imageCheck.js');
var base = require('base/search/search');

function closeAllFilter() {
    $('body').removeClass('hidden');
    $('.side-bar-fliters').removeClass('active');
    $('.overlay-block').removeClass('active');
}

function customPLP() {
    $('.products-main-block').on('click', '.card-header', function (e) {
        e.stopPropagation();
        if ($(this).hasClass('current')) {
            $('.card-header').removeClass('current');
            $(this).next('.card-body').removeClass('active');
        } else {
            $('.card-body').removeClass('active');
            $('.card-header').removeClass('current');
            $(this).addClass('current');
            $(this).next('.card-body').addClass('active');
        }
    });

    $('body').on('click', '.allfliter-sort, .allfliter-left', function () {
        $('.side-bar-fliters').addClass('active');
        $('body').addClass('hidden');
        $('.overlay-block').addClass('active');
    });

    $('body').on('click', '.close-side-fliters', function () {
        closeAllFilter();
    });

    $('body').click(function (e) {
        if (!$(e.target).closest('.allfliter-left').length && !$(e.target).closest('.side-bar-fliters').length) {
            if ($(window).width() > 1024) {
                closeAllFilter();
            }
        }
    });

    $('body').on('click', '.content-search', function () {
        $('.products-main-block').addClass('de-active');
        $('.artical-block').addClass('active');
    });
    $('body').on('click', '.seeallContent', function () {
        $('.contnet-block').addClass('d-none');
        var id = $(this).attr('data-type');
        $('#' + id).addClass('active-content-block');
        $('.products-main-block').addClass('de-active');
        $('.artical-block').addClass('active');
        $('.contnet-block .d-none').removeClass('d-none');
    });
    $('body').on('click', '.backToContent', function () {
        $('.contnet-block').removeClass('d-none');
        $('.contnet-block').removeClass('active-content-block');
        $('.pagination-wrapper').addClass('d-none');
        var parentId = $(this).parents('.contnet-block').attr('id');
        var i = 1;
        $('#' + parentId + ' .artical-grids-block .artical-inner-block').each(function () {
            if (i > 4) {
                $(this).addClass('d-none');
            }
            i++;
        });
    });
}

const initSortValue = ($document) => {
    var $mobileSort = $('.allfliter-block .sort-card-header');
    $document = $document && $document.length !== 0 ? $document : $('.side-bar-fliters');
    
    const selectedSortValue = $document.find('.swatch-round input[type="radio"]:checked');

    if (!selectedSortValue || selectedSortValue.length === 0) {
        return;
    }

    const [label, value] = selectedSortValue.data('card-header-text').split(':');
    const sortHeaderHtml = `${label}: <span class="sort-header-value">${value}</span>`;

    if($mobileSort.is(':visible')) {
        $mobileSort.html(sortHeaderHtml);
    }

    $('.product-refinement-filter-block').css('top', $('header').height()-1);
    $document.find('.sort-card-header').html(sortHeaderHtml);
};


/**
 * Update DOM elements with Ajax results
 *
 * @param {Object} $results - jQuery DOM element
 * @param {string} selector - DOM element to look up in the $results
 * @return {undefined}
 */
function updateDom($results, selector, skipIfEmpty) {
    var $updates = $results.find(selector);
    if(skipIfEmpty && !$updates.length) {
        return;
    }
    if($('.compare-bar-wrapper').is(':visible')) {
        $('.slot-with-product').each(function () {
            var pid=$(this).data('pid');
            $updates.find(`.add-to-compare[data-id="${pid}"]`).addClass('added-to-compare');;
         });
    }
    if($('.side-bar-fliters').hasClass('active')) {
        $updates.find('.side-bar-fliters').addClass('active');
    }
    $(selector).empty().html($updates.html()); 
    initSortValue($updates);
    $(document).trigger('filter:update');
}

function customTab() {
    $('.search-resutl-tabs .nav-item').on('click', function (e) {
        e.preventDefault();
        $('.search-resutl-tabs .nav-item').removeClass('active');
        $(this).addClass('active');
    });
}

function getRefinementUrl(url) {
    var pageUrl = window.location.href.split('?');
    var currentUrl = url.split('?');
    var params = currentUrl[1];
    if (pageUrl.indexOf('cgid') !== -1 && currentUrl[1].indexOf('cgid') !== -1) {
        var paramsArray = currentUrl[1].split('&');
        paramsArray.shift();
        params = paramsArray.join('&');
    }
    var finalUrl = pageUrl[0] + '?' + params;
    return finalUrl;
}

function getHashParameter(url) {
    var hashParameter = '';
    // eslint-disable-next-line no-param-reassign
    url = url.split('#');
    if (url[1]) {
        if (url[1].indexOf('?') > -1) {
            hashParameter = '#' + url[1].split('?')[0];
        } else {
            hashParameter = '#' + url[1];
        }
    }
    return hashParameter;
}

function initTileSlider() {
    var $featuredUnitModals = $('.featured-models-block .floorPlansModal')
    //move modal window out of slider
    if($featuredUnitModals.length > 0) {
        $featuredUnitModals.appendTo($('.featured-models-block'));
    
    }
    $('.image-container.tile-slider').each(function() {
        var $container = $(this);
        if($container.find('img').length > 1) {
            $container.slick({
                dots:true,
                dotsClass: 'slick-dots tile-dots',
                prevArrow: '<button type="button" class="slick-prev tile-slick-arrow tile-prev">Previous</button>',
                nextArrow: '<button type="button" class="slick-next tile-slick-arrow tile-next">Next</button>'
            });
        } else {
            $container.css('display', 'flex');
            $container.find('img').css('align-self', 'center');
        }
    });
    $('.image-container img').removeClass('d-none');
}

module.exports = {
    filter: base.filter,
    closeRefinements: base.closeRefinements,
    resize: base.resize,
    sort: function () {
        // Handle sort order menu selection
        $('.container').on('click', '.sort-order-menu', function (e) {
            e.preventDefault();
            $.spinner().start();
            var url = $(this).attr('value');
            if ($(this).hasClass('view-all-results')) {
                url = window.searchRefinementBannerUrl;
                $('.close-side-fliters').trigger('click');
            } else {
                window.searchRefinementBannerUrl = url;
            }
            $(this).trigger('search:sort', this.value);
            $(this).closest('.card-body').find('input').prop('checked', false);
            $(this).find('input').prop('checked', 'checked');
            var isBlogPage = $('.blogs-tiles').length > 0;
            if (isBlogPage && $(this).hasClass('tab-link')) {
                $('.blogs-tabs .tab-text.active').removeClass('active');
                $(this).closest('.tab-text').addClass('active');
            }
            $.ajax({
                url: url,
                data: { selectedUrl: this.value },
                method: 'GET',
                success: function (response) {
                    var $results = $(response);
                    updateDom($results, '.products-main-block');
                    initTileSlider();
                    $('.product-refinement-filter-block .allfliter-block').find('.card-header').addClass('current');
                    $('.product-refinement-filter-block .allfliter-block').find('.card-body').addClass('active');
                    $.spinner().stop();
                    // Replace not loaded or corrupted images
                    setTimeout(() => {
                        initImageCheck();
                    }, 500);
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
    },
    applyPagination: function () {
        $('.container').on('click', '.pagination-block a, .sales-search, .rent-search, .blogs-tabs a', function (e) {
            e.preventDefault();

            $.spinner().start();
            $('.products-main-block').removeClass('de-active');
            $('.artical-block').removeClass('active');
            var url = $(this).attr('href');
            $(this).trigger('search:pagination', this.value);
            var isBlogPage = $('.blogs-tiles').length > 0;
            if (isBlogPage && $(this).hasClass('tab-link')) {
                $('.blogs-tabs .tab-text.active').removeClass('active');
                $(this).closest('.tab-text').addClass('active');
                url = $(this).data('href');
                var hashParameter = getHashParameter(window.location.href);
                var tabID = $(this).attr('href');
                if (hashParameter) {
                    window.history.pushState('', '', window.location.href.replace(hashParameter, tabID));
                } else {
                    window.history.pushState('', '', window.location.href + tabID);
                }
            }
            var urlObj;
            if (url.indexOf('isSearch') > -1) {
                urlObj = new URL(url);
            }
            var isContentAjaxRequest = false;
            if (url && url.indexOf('fdid') > -1) {
                isContentAjaxRequest = true;
            }

            var parentContentId = $(this).parents('.contnet-block').attr('id');
            var isContentSearchPage = urlObj && urlObj.searchParams.get('isSearch') === 'true';
            $.ajax({
                url: url,
                data: { selectedUrl: $(this).attr('href') },
                method: 'GET',
                success: function (response) {
                    var $results = $(response);
                    if (isContentSearchPage) {
                        $('#' + parentContentId + ' .artical-grids-block').empty().html($(response).html());
                        $('.products-main-block').addClass('de-active');
                        $('.artical-block').addClass('active');
                    } else if (isBlogPage) {
                        $('.blogs-tiles').empty().html($(response).html());
                    } else {
                        updateDom($results, '.products-main-block');
                        initTileSlider();
                        $(window).scrollTop(0);
                    }
                    if (!isContentSearchPage && !isContentAjaxRequest) {
                        var finalUrl = getRefinementUrl(url);
                        window.history.pushState({ html: response.html, pageTitle: response.pageTitle }, '',
                        finalUrl.replace('fdid', 'cgid'));
                    }
                    // Replace not loaded or corrupted images
                    setTimeout(() => {
                        initImageCheck();
                    }, 500);
                    $.spinner().stop();
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
    },
    applyFilter: function () {
        const filterFn = function (e) {
            var isResetClick = $(this).hasClass('reset');
            var viewAllClick = $(this).hasClass('view-all-results');
            e.preventDefault();
            e.stopPropagation();
            var $element = $(this);
            var radiotype = $(this).data('radiotype');
            var refinementID = $(this).data('refinementid');
            var refinementValue = $(this).data('refinementvalue');
            var customRefinement = $element.data('customrefinement');
            var customRefinements = $element.data('customrefinements');
            var url = $(this).data('href');

            if (radiotype) {
                var urlArray = url.split('?');
                var refinementNumber;
                var refValue;
                urlArray[1].split('&').forEach(function (ele) {
                    if (ele.includes(refinementID) && ele.includes('prefn')) {
                        refinementNumber = ele[5];
                    }
                });
                urlArray[1].split('&').forEach(function (ele) {
                    if (ele.includes('prefv' + refinementNumber)) {
                        refValue = 'prefv' + refinementNumber + '=' + encodeURIComponent(refinementValue);
                        url = url.replace(ele, refValue);
                    }
                });
            } else if (url && customRefinement) {
                const urlArray = window.location.href.split('?');
                const searchParams = new URLSearchParams(urlArray[1] || '');
                const newSearch = new URLSearchParams('');
                let prefCounter = 1;

                searchParams.forEach((value, key) => {
                    const isPrefn = key.indexOf('prefn') > -1;
                    const isPrefv = key.indexOf('prefv') > -1;
                    const isPref = isPrefn || isPrefv;
                    const isCustom = Object.keys(customRefinements).find(function (customKey) {
                        const current = customRefinements[customKey];
                        return current.id === value;
                    });

                    if (!isPref) {
                        newSearch.set(key, value);
                    } else if (!isPrefv && !isCustom) {
                        const index = key.split('prefn')[1];

                        newSearch.set('prefn' + prefCounter, value);
                        newSearch.set('prefv' + prefCounter, searchParams.get('prefv' + index));
                        prefCounter++;
                    }
                });

                Object.keys(customRefinements).map(function (key) {
                    const current = customRefinements[key];

                    if (current.selected && refinementID !== current.id){
                        newSearch.set('prefn' + prefCounter, current.id);
                        newSearch.set('prefv' + prefCounter, 'true');
                        prefCounter++;
                    }
                });

                if (!customRefinement.selected) {
                    if (newSearch.has('clearAll')) newSearch.delete('clearAll');

                    newSearch.set('prefn' + prefCounter, customRefinement.id);
                    newSearch.set('prefv' + prefCounter, 'true');
                }

                url = urlArray[0] + '?' + newSearch.toString();
            }

            if (viewAllClick) {
                url = window.searchRefinementBannerUrl;
            } else {
                window.searchRefinementBannerUrl = url;
            }
            
            $.spinner().start();
            $(this).trigger('search:filter', e);
            var topBlockID = $(this).closest('.refinement').attr('id');
            $.ajax({
                url: url,
                data: {
                    page: $('.grid-footer').data('page-number'),
                    selectedUrl: url
                },
                method: 'GET',
                // context: this,
                success: function (response) {
                    var $results = $(response);
                    if(viewAllClick) {
                        $('.close-side-fliters').trigger('click');
                    }
                    updateDom($results, '.products-main-block');
                    $('.side-bar-fliters #' + topBlockID).find('.card-header').addClass('current');
                    $('.side-bar-fliters #' + topBlockID).find('.card-body').addClass('active');
                    initTileSlider();
                    var finalUrl = getRefinementUrl(url);
                    window.history.pushState({ html: response.html, pageTitle: response.pageTitle }, '', finalUrl);
                    // Replace not loaded or corrupted images
                    setTimeout(() => {
                        initImageCheck();
                    }, 500);
                    $.spinner().stop();
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }
        // Handle refinement value selection and reset click
        $('.container').on(
            'click',
            '.refinements li button, .filter-reset-button button, .side-bar-bottom .view-all-results, .filter-value button, .swatch-filter button',
            filterFn);

            $('.container').on('change', '.slider-control', filterFn);
            $('.container').on('change', '.attribute-slider-control', filterFn);
    },
    handleBlogPageTabs: function () {
        var hashParameter = getHashParameter(window.location.href);
        if (hashParameter) {
            $('.blogs-tabs a[href*="' + hashParameter + '"]').click();
        }
    },
    showMore: base.showMore,
    showContentTab: base.showContentTab,
    customPLP: customPLP,
    customTab: customTab,
    initTileSlider: initTileSlider,
    initSortValue: initSortValue
};
